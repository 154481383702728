<template></template>
<script>
import { mapActions } from 'vuex'
export default {
  name: 'UserBecome',
  methods: {
    ...mapActions(
      'loginAs',
      'revertLoginAs'
    ),
    doLogInAs (username) {
      this.loginAs(username).then(() => {
        this.$router.push('/')
      })
    },
    doRevertLoginAs () {
      this.revertLoginAs().then(() => {
        this.$router.push({ name: 'home' })
      })
    }
  }
}
</script>